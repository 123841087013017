import React from "react"
import { Helmet } from "react-helmet"

function SEO({ children, location, description, title, image }) {
  return (
    <Helmet titleTemplate={`%s - Verse Collective`}>
      <html lang="en" />
      <title>{title}</title>
      {/* FAVICONS */}
      <link rel="icon" href="/verse_logo.ico" />
      {/* META */}
      <meta name="viewport" content="width=device0-width, initial-scale=1.0" />
      <meta charset="utf-8" />
      <meta name="description" content={description} />
      {/* OPENGRAPH */}
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={image} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:site_name"
        content="Verse Collective | cafe • cowork • studio • hostel • shop • rooms"
        key="ogsitename"
      />
      <meta
        property="og:description"
        content={description}
        key="ogdescription"
      />
      {children}
    </Helmet>
  )
}

export default SEO
